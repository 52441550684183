<template>
  <div class="unique-feature-second dark_bg">
    <!-- <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition> -->
    <!-- 头部 -->
    <van-nav-bar
      :title="categoryName"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="1000"
      class="dark_bg dark_text"
    ></van-nav-bar>
    <!-- 介绍图片 -->
    <van-image :src="$route.query.imgUrl" width="100%"></van-image>
    <!-- 介绍文字 -->
    <section class="desc-info">
      <h2 class="dark_text">城市概况</h2>
      <div v-html="$route.query.detail" class="desc-info-content"></div>
    </section>
    <!-- 视频介绍 -->
    <div id="feature-video" class="feature-video"></div>
    <!-- 商品列表 -->
    <!-- <div class="feature-goods">
      <h2 class="dark_text">特选优品</h2>
      <van-list v-model="loading"
        :finished="finished"
        :finished-text="$globalConfig.finishedText"
        @load="onLoad"
        :error.sync="error"
        offset:100>
        <goods-row :goodsList="goodsList"></goods-row>
        <p slot="loading">
          <img
            src="../../assets/images/index.messenger-typing-preloader.svg"
            alt
            style="width:20%;"
          />
        </p>
      </van-list>
    </div> -->
  </div>
</template>

<script>
import Player from "xgplayer";
import { NavBar, Image, Field, List } from "vant";
import GoodsRow from "@/components/public/goodsRow";
export default {
  data() {
    return {
      featureInfo: {},
      autosize: { maxHeight: 100, minHeight: 50 },
      isLoading: true,
      loading: false,
      finished: false,
      error: false,
      currentPage: 1,
      totalPage: Number,
      categoryId: null,
      categoryName: this.$route.query.categoryName,
      player: null,
      goodsList: [],
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Field.name]: Field,
    [List.name]: List,
    GoodsRow,
  },
  created() {
    // console.log("this.$route.query", this.$route.query);
  },
  mounted() {
    this.initVideo(this.$route.query.videoURL, this.$route.query.VCSUrl);
  },
  methods: {
    onClickLeft() {
      this.$router.back(0);
    },
    // 获取数据
    getData() {
      if (this.currentPage > this.totalPage) {
        this.loading = false;
        // 恢复currentPage和totalPage的值，以供分类分页使用
        return (this.finished = true);
      }
      this.categoryName = this.$route.query.categoryName;
      this.categoryId = this.$route.params.categoryId;
      this.$api.home
        .YunNanSpecialAddress({
          categoryId: this.categoryId,
          currentPage: this.currentPage,
        })
        .then((res) => {
          if (res.code === 100) {
            this.isLoading = false;
            this.loading = false;
            this.totalPage = res.returnValue.content.totalPage;
            if (this.currentPage === 1) {
              let { VCSUrl, videoURL } = res.returnValue.category;
              this.featureInfo = res.returnValue.category;
              this.goodsList = res.returnValue.content.data;
              this.initVideo(videoURL, VCSUrl);
            } else {
              this.goodsList = this.goodsList.concat(
                res.returnValue.content.data
              );
            }
            this.currentPage++;
          } else {
            this.error = false;
          }
        });
    },
    initVideo(url = "", poster) {
      this.player = new Player({
        id: "feature-video", // 播放器绑定元素
        url, // 视频地址
        poster, // 视频封面图
        width: "100%", // 播放器宽度
        fitVideoSize: "fixWidth", // 宽度固定高度自适应
        playsinline: true, // 内联模式
        "x5-video-player-type": "h5",
      });
    },
    onLoad() {
      // 异步获取数据
      setTimeout(() => {
        // this.getData();
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.unique-feature-second {
  width: 100%;
  box-sizing: border-box;
  padding: 0.96rem /* 48/50 */ 0 0.64rem /* 32/50 */;
  min-height: 100vh;
  background-color: #fff;
  .van-nav-bar {
    .van-icon {
      color: #ccc;
      font-size: 0.38rem /* 19/50 */;
    }
  }
  .desc-info {
    width: 100%;
    box-sizing: border-box;
    font-size: 0.28rem /* 14/50 */;
    line-height: 1.6;
    padding: 0.16rem /* 8/50 */ 0.24rem /* 12/50 */;
    min-height: 1.92rem /* 96/50 */;
    max-height: 3.2rem /* 160/50 */;
    overflow-y: auto;
    margin: 0 0 0.32rem /* 16/50 */;
    h2 {
      text-align: center;
      line-height: 2;
      font-size: 0.32rem /* 16/50 */;
    }
    .desc-info-content {
      text-indent: 2em;
    }
  }
  .feature-goods {
    h2 {
      text-align: center;
      line-height: 3;
      letter-spacing: 1px;
      font-size: 0.28rem /* 14/50 */;
    }
  }
}
</style>